import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PreviewPage } from './pages/PreviewPage';

export const RoutesFind = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="" component={Dashboard} /> */}
        <Route path="/" element={<PreviewPage />} />
        {/* <Route path="/profile" element={<Settings />} />
        <Route path="/farming" element={<Farming />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/about" element={<About />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/programs">
          <Route index path="/programs" element={<Programs />} />
          <Route path="/programs/frgx" element={<ProgramFrgx />} />
          <Route path="/programs/usdt" element={<ProgramUsdt />} />
        </Route> */}
      </Routes>
    </Router>
  );
};
