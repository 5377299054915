import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultLayout } from '../../layouts';

export const PreviewPage = () => {
  const socials = [
    {
      title: 'telegram',
      img: '/socials/telegram.webp',
      url: 'https://t.me/polynetica',
    },
    {
      title: 'x',
      img: '/socials/x.webp',
      url: 'https://x.com/polynetica',
    },
    {
      title: 'youtube',
      img: '/socials/youtube.webp',
      url: 'https://www.youtube.com/@Polynetica',
    },
  ];

  const polyBlock = (isSmHidden = false) => {
    return (
      <div
        className={`${
          isSmHidden ? 'sm:hidden flex' : 'hidden sm:flex'
        } poly-animation-show sm:w-full items-center justify-center absolute h-[78.4rem] right-[-5%] top-1/2 -translate-y-1/2 sm:relative sm:right-auto sm:top-auto sm:translate-y-0 sm:h-[40rem] sm:min-w-[50rem]`}
      >
        <div className="absolute h-full w-full left-0 top-0 w-full previewPage-polyBg z-[5]"></div>
        <img
          className="custom-animation-bounce h-[20rem] absolute bottom-[10rem] left-[5rem] z-[3] sm:h-[10rem]"
          src="/previewPage/coin-1.webp"
          alt=""
        />
        <img
          className="custom-animation-bounce h-[15rem] absolute top-[10%] left-[25%] z-[3] sm:h-[7.5rem]"
          src="/previewPage/coin-2.webp"
          alt=""
        />
        <img
          className="custom-animation-bounce h-[25rem] absolute top-[30%] right-[10%] z-[3] sm:h-[12rem]"
          src="/previewPage/coin-3.webp"
          alt=""
        />
        <img
          className="max-h-[80vh] h-full z-[4] min-w-[80rem] sm:min-w-[50rem] w-auto sm:max-h-[51.2rem]"
          src="/previewPage/poly.webp"
          alt=""
        />
        <img
          className="absolute h-auto top-0 left-[-10%] z-[2] w-full z-[0] sm:left-[5%]"
          src="/previewPage/bg-blocks.webp"
          alt=""
        />
      </div>
    );
  };

  return (
    <DefaultLayout>
      <header className="sm:backdrop-blur-md max-w-[144rem] z-[10] fixed top-0 left-1/2 -translate-x-1/2 w-full sm:bg-[rgba(2,2,4,0.80)] sm:max-w-full sm:py-[1rem] sm:px-[1.6rem] py-[3rem] pl-[6rem] pr-[4rem] flex items-center justify-between">
        <img className="h-[4rem] sm:h-[3rem]" src="/logo.svg" alt="" />
        <Link
          className="previewPage-header-btn rounded-[1.2rem] border border-solid border-[rgba(255,255,255,0.5)] bg-[#985AFF] hover:bg-[#B678F5] transition-all flex items-center justify-center space-x-[1rem] py-[0.8rem] px-[1.6rem]"
          to="https://t.me/polynetica"
          target="_blank"
        >
          <span className="text-white text-[1.6rem] font-medium">Get info</span>
          <img className="h-[2.4rem] w-[2.4rem]" src="/icons/bookmark.svg" alt="" />
        </Link>
      </header>

      <div className="min-h-screen relative flex flex-1 flex-col items-start justify-center pl-[6rem] pr-[4rem] sm:px-[5rem] w-full max-w-[144rem] sm:pt-[10rem] sm:mb-[8rem]">
        <div className="max-w-[76.1%] w-full flex flex-col items-start justify-center sm:items-center sm:max-w-full sm:text-center">
          <div className="w-full flex flex-col items-start justify-center sm:items-center sm:max-w-full sm:text-center">
            <span className="sm:order-2 z-[2] text-white text-[1.4rem] font-light max-w-[40.5rem] w-full mb-[3rem] sm:text-[1.4rem] sm:mb-[1.2rem] sm:max-w-[32.9rem]">
              Welcome to the future of decentralized financial operations with Polynetica, your gateway to seamless
              online business partnerships.
            </span>
            <div className="relative flex flex-col items-start sm:items-center space-grotesk sm:order-1 z-[2] text-[12rem] font-semibold leading-[11rem] text-white mb-[6rem] sm:text-[7rem] sm:leading-[7.4rem] sm:mb-[1.2rem]">
              <span>Polynetica</span>
              <span className="relative sm:flex sm:flex-col sm:space-y-[1.2rem]">
                <span className="gradient-text-purple">Gates</span>
                <span className="gradient-text-purple absolute bottom-[0.5rem] right-[-55%] text-[2.4rem] leading-[3.2rem] tracking-normal font-medium sm:static sm:bottom-auto sm:right-auto sm:text-[2rem]">
                  Opening Soon
                </span>
              </span>
            </div>
          </div>
          <div className="relative flex flex-col z-[2] space-y-[3rem] sm:items-center">
            {polyBlock(false)}
            <div className="flex items-center justify-start space-x-[0.9rem] sm:flex-col sm:space-x-0 sm:space-y-[0.9rem] !mt-0 sm:mt-[3rem]">
              <div className="previewPage-note"></div>
              <span className="space-grotesk text-white text-[1.8rem] leading-[2.4rem]">
                Join us on our social networks. <br /> The story begins there.
              </span>
            </div>
            <div className="flex items-center justify-start space-x-[5.4rem]">
              {socials.map((item, itemIndex) => {
                return (
                  <Link
                    to={item.url}
                    target="_blank"
                    className="group transition-all hover:grayscale hover:brightness-75 "
                    key={itemIndex}
                  >
                    <img
                      className="transition-all h-[4rem] w-auto group-hover:-translate-y-[1rem]"
                      src={item.img}
                      alt={item.title}
                    />
                  </Link>
                );
              })}
            </div>
            <img
              className="absolute -right-[20rem] bottom-[-1.25rem] max-h-[10rem] h-full w-auto sm:static sm:right-0 sm:bottom-0"
              src="/previewPage/label.webp"
              alt=""
            />
          </div>
        </div>
        {polyBlock(true)}
      </div>

      <footer className="z-[2] w-full pb-[4rem] px-[4rem] flex items-center justify-center">
        <span className="font-light text-[1.4rem] leading-[140%] text-white-500">
          © 2024 Polynetica. All rights reserved.
        </span>
      </footer>
      <img
        className="absolute top-0 left-0 w-full h-auto sm:w-[100rem] sm:left-[-5rem] sm:h-[50rem] z-[0]"
        src="/previewPage/topLight-purple.webp"
        alt=""
      />
    </DefaultLayout>
  );
};
